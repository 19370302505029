import React from 'react'
import About from '../components/about/About'
import Landing from '../components/about/Landing'
import Teams from '../components/about/Teams'
import WhatWeDo from '../components/about/WhatWeDo'

const AboutScreen = () => {
  return (
    <>
      <Landing />
      <About />
      <WhatWeDo />
      <Teams />
    </>
  )
}

export default AboutScreen
