import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Samer from '../../images/samer-teams.jpg'
import Iyad from '../../images/iyad-teams.jpg'

const Teams = () => {
  return (
    <Container className='py-5 teams'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1 className='pb-5'>The Team</h1>
        </Col>
      </Row>
      <Row className='text-center'>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img src={Samer} alt='' />
          <h4 className='pt-3'>Samer Murad</h4>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img src={Iyad} alt='' />
          <h4 className='pt-3'>Iyad Dirany</h4>
        </Col>
      </Row>
    </Container>
  )
}

export default Teams
