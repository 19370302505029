import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'
import * as MdIcons from 'react-icons/md'

const Footer = () => {
  return (
    <footer className='py-5'>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <p>Colorbars</p>
            <p>© 2021 All Rights Reserved</p>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <ul>
              <li>
                <a
                  href='https://www.facebook.com/ColorbarsSarl'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaIcons.FaFacebookF
                    fill='black'
                    color='black'
                    fontSize='25'
                  />
                </a>
              </li>
              <li>
                <a
                  href='https://youtube.com/channel/UCpaKGeYPEQP8Oklh7ZBN2Tw'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaIcons.FaYoutube fill='black' color='black' fontSize='25' />
                </a>
              </li>
              <li>
                <a
                  href='https://goo.gl/maps/ZGTFqtAwG7q69Thz8'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FaIcons.FaMapMarkerAlt
                    fill='black'
                    color='black'
                    fontSize='25'
                  />
                </a>
              </li>
              <li>
                <a href='tel:+9613932594' target='_blank' rel='noreferrer'>
                  <FaIcons.FaPhone fill='black' color='black' fontSize='25' />
                </a>
              </li>
              <li>
                <a
                  href='mailto:iyad@colorbars.tv'
                  target='_blank'
                  rel='noreferrer'
                >
                  <MdIcons.MdEmail fill='black' color='black' fontSize='25' />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
