import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ProjectCard from '../components/projects/ProjectCard'
import Clients from '../data/Clients'

const ProjectScreen = () => {
  return (
    <>
      <Container className='py-5 project'>
        <Row className='pt-5'>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1>Reels</h1>
          </Col>
        </Row>
      </Container>
      <Container className='py-5'>
        <Row>
          {Clients.map((project, i) => (
            <Col lg={12} md={12} sm={12} xs={12} key={project.id}>
              <ProjectCard data={project} />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default ProjectScreen
