import React from 'react'
import Contact from '../components/home/Contact'
import Landing from '../components/home/Landing'
import Reels from '../components/home/Reels'
import WhyHome from '../components/home/WhyHome'

const HomeScreen = () => {
  return (
    <>
      <Landing />
      <Reels />
      <WhyHome />
      <Contact />
    </>
  )
}

export default HomeScreen
