import React from 'react'
import './About.css'
import { Col, Container, Row } from 'react-bootstrap'
import Studio from '../../images/Studio.svg'

const About = () => {
  return (
    <Container className='py-5 about'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1>Who we are?</h1>
        </Col>
      </Row>
      <Row className='justify-content-center align-items-center '>
        <Col lg={6} md={6} sm={12} xs={12}>
          <p>
            We are more than just a production house to our clients, we are a
            creative and strategic extension of their own teams and strategies.
            This personalized service is integral to the success of our works,
            and allows us to provide products solutions.
          </p>
          <p>
            Colorbars provides comprehensive production solutions including
            facilities and services such as concept, realization, content
            generation, pre-production, and post-production.
          </p>
          <p>
            Colorbars provides filming solutions, to meet clients’ targets, and
            it include: Marketing content development, documentary marketing
            consultancy, journalistic content, documentary research, planning &
            consultancy.
          </p>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <img src={Studio} alt='' />
        </Col>
      </Row>
    </Container>
  )
}

export default About
