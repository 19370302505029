import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import YoutubeEmbed from '../utils/YoutubeEmbed'

const Reels = () => {
  return (
    <Container className='py-5 reels-home'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1>Recent Films</h1>
        </Col>
      </Row>
      <Row className='py-3'>
        <Col lg={4} md={4} sm={12} xs={12} className='pb-3'>
          <YoutubeEmbed embedId='VHS00ERrJ0Q' />
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className='pb-3'>
          <YoutubeEmbed embedId='TWVFfJ4gMrs' />
        </Col>
        <Col lg={4} md={4} sm={12} xs={12} className='pb-3'>
          <YoutubeEmbed embedId='BEk3ZmEWb74' />
        </Col>
      </Row>
      <Row>
        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className='pb-3 d-flex justify-content-end'
        >
          <Link to='/projects'>Watch More</Link>
        </Col>
      </Row>
    </Container>
  )
}

export default Reels
