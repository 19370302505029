import { motion } from 'framer-motion'
import React from 'react'
import BackgroundImage from '../../images/about.jpg'

const Landing = () => {
  return (
    <div style={{ position: 'relative' }}>
      <img src={BackgroundImage} className='w-100' alt='' />
      <div className='landing-overlay about'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: 'anticipate',
            duration: 1.5,
          }}
        >
          <h4>“Everything I learned I learned from the movies.”</h4>
          <p>― Audrey Hepburn</p>
        </motion.div>
      </div>
    </div>
  )
}

export default Landing
