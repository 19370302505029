import React, { useEffect, useRef, useState } from 'react'
import { Nav, Navbar, Image } from 'react-bootstrap'
import './Nav.css'
import Logo from '../../images/logoCB-mini.svg'
import { LinkContainer } from 'react-router-bootstrap'
import { HashLink } from 'react-router-hash-link'

const Header = () => {
  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  navRef.current = navBackground

  const handleScroll = () => {
    if (window.scrollY >= 66) {
      setNavBackground(true)
    } else {
      setNavBackground(false)
    }
  }
  useEffect(() => {
    handleScroll()
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Navbar
      bg='custom'
      variant='custom'
      expand='lg'
      fixed='top'
      style={
        navBackground
          ? {
              transition: '1s ease',
              background: 'black',
            }
          : { background: 'transparent' }
      }
    >
      <Navbar.Brand href='#home'>
        <LinkContainer to='/'>
          <Image src={Logo} width='30%' />
        </LinkContainer>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto'>
          <LinkContainer to='/'>
            <Nav.Link activeClassName='active'>Home</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/about'>
            <Nav.Link activeClassName='active'>About</Nav.Link>
          </LinkContainer>
          <LinkContainer to='/projects'>
            <Nav.Link activeClassName='active'>Projects</Nav.Link>
          </LinkContainer>
          <Nav.Link as={HashLink} to='/#contact' activeClassName='active'>
            Contact
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
