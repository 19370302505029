const Clients = [
  {
    id: '0',
    name: 'Greenpeace MENA',
    urlPath: [{ url: 'B2OsoOdrW7k' }],
    logo: '/images/clients/gp.png',
  },
  {
    id: '1',
    name: 'UNHCR',
    urlPath: [
      { url: 'HY6NsGEfYuE' },
      { url: 'eVBQ0IcHqmo' },
      { url: 'CpAR8Ekg1NM' },
      { url: 'TWVFfJ4gMrs' },
      { url: 'L8NJOXKiPb8' },
    ],
    logo: '/images/clients/unhcr.png',
  },
  {
    id: '2',
    name: 'Qatar Red Crescent Society',
    urlPath: [{ url: '06urZVQiRDU' }],
    logo: '/images/clients/qrcs.png',
  },
  {
    id: '3',
    name: 'OMSAR - Lebanese Government',
    urlPath: [{ url: 'PXttOn9KOgY' }],
    logo: '/images/clients/omsar.jpeg',
  },
  {
    id: '4',
    name: 'UN-Habitat Lebanon',
    urlPath: [
      { url: 'VHS00ERrJ0Q' },
      { url: 'exNQ1P-fDPs' },
      { url: '_JHQHforBqE' },
      { url: 'hsTkeLfvDxc' },
    ],
    logo: '/images/clients/unh.jpeg',
  },
  {
    id: '5',
    name: 'BBC Arabic',
    urlPath: [{ url: 'sRSVEf46Yio' }],
    logo: '/images/clients/bbc.png',
  },
  {
    id: '6',
    name: 'Nexans',
    urlPath: [{ url: 'PvmUpVukUqA' }],
    logo: '/images/clients/nexans.png',
  },
  {
    id: '7',
    name: 'Merck',
    urlPath: [{ url: 'OVxxOePrRws' }, { url: 'mxB0JnZ5Fcw' }],
    logo: '/images/clients/merck.png',
  },
]
export default Clients
