import React from 'react'
import './Home.css'
import BackgroundImage from '../../images/landing.jpg'
import Logo from '../../images/colorbars-logo.svg'
import { motion } from 'framer-motion'

const Landing = () => {
  return (
    <div style={{ position: 'relative' }}>
      <img src={BackgroundImage} className='w-100' alt='' />
      <div className='landing-overlay'>
        <motion.img
          src={Logo}
          alt=''
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: 'anticipate',
            duration: 1.5,
          }}
        />
      </div>
    </div>
  )
}

export default Landing
