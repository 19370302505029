import { BrowserRouter as Router, Route } from 'react-router-dom'
import Footer from './components/navigation/Footer'
import Header from './components/navigation/Header'
import AboutScreen from './screens/AboutScreen'
import HomeScreen from './screens/HomeScreen'
import ProjectScreen from './screens/ProjectScreen'
import ScrollToTop from './components/utils/ScrollToTop'

function App() {
  return (
    <div className='App'>
      <Router>
        <ScrollToTop />
        <Header />
        <main>
          <Route path='/' exact={true} component={HomeScreen} />
          <Route path='/about' component={AboutScreen} />
          <Route path='/projects' component={ProjectScreen} />
        </main>
        <Footer />
      </Router>
    </div>
  )
}

export default App
