import React from 'react'
import './About.css'
import { Col, Container, Row } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'
import * as GiIcons from 'react-icons/gi'
import * as BsIcons from 'react-icons/bs'
import * as CgIcons from 'react-icons/cg'
import * as RiIcons from 'react-icons/ri'

const WhatWeDo = () => {
  return (
    <Container className='py-5 wwd'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1>What we do?</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <p>
            Colorbars is a regional film production house based in Beirut –
            Lebanon. After more than two decades in the film industry & Media
            sectors, we provide our clients with outstanding works:
          </p>
        </Col>
      </Row>
      <Row className='text-center py-5'>
        <Col lg={4} md={4} sm={12} xs={12}>
          <FaIcons.FaFilm fontSize='80' />
          <h6>Documentary & Mini Docs</h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <GiIcons.GiFilmProjector fontSize='80' />
          <h6>PSAs, TVCs, Corporate Films & news reports</h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <BsIcons.BsFillCameraVideoFill fontSize='80' />
          <h6>Fundraising film solutions </h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <GiIcons.GiMicrophone fontSize='80' />
          <h6>Live news coverage & TV Programs services </h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <CgIcons.CgMediaPodcast fontSize='80' />
          <h6>Digital Media solutions</h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <CgIcons.CgGhostCharacter fontSize='80' />
          <h6>Animation & 3D</h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <RiIcons.RiSoundModuleFill fontSize='80' />
          <h6>Sound studio & Audio editing </h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <CgIcons.CgColorPicker fontSize='80' />
          <h6>Color grading</h6>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <BsIcons.BsPeopleFill fontSize='80' />
          <h6>Media consultancy & filming strategy support</h6>
        </Col>
      </Row>
    </Container>
  )
}

export default WhatWeDo
