import { motion } from 'framer-motion'
import React from 'react'
import './Forms.css'
import { Col, Form, FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = (data, e) => {
    e.target.reset()
  }

  return (
    <Form className='contact-form' onSubmit={handleSubmit(onSubmit)}>
      <FormGroup as={Col} controlId='formName'>
        <Form.Label>
          Name
          <span>*</span>
        </Form.Label>
        <Form.Control {...register('name', { required: true })} type='text' />
      </FormGroup>
      <FormGroup as={Col} controlId='formLast'>
        <Form.Label>
          Last
          <span>*</span>
        </Form.Label>
        <Form.Control {...register('last', { required: true })} type='text' />
      </FormGroup>

      {errors.name && errors.last && <p>These Fields is required!</p>}

      <FormGroup as={Col} controlId='formEmail'>
        <Form.Label>
          Email <span>*</span>
        </Form.Label>
        <Form.Control {...register('email')} type='text' />
      </FormGroup>
      <FormGroup as={Col} controlId='formNumber'>
        <Form.Label>
          Phone Number <span>*</span>
        </Form.Label>
        <Form.Control {...register('number')} type='text' />
      </FormGroup>

      {errors.email && errors.number && <p>This Field is required!</p>}
      <div className='text-center'>
        <motion.button
          type='submit'
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{ scale: 0.8 }}
          className='submit-button '
        >
          Submit
        </motion.button>
      </div>
    </Form>
  )
}

export default ContactForm
