import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import YoutubeEmbed from '../utils/YoutubeEmbed'
import './Projects.css'

const ProjectCard = ({ data }) => {
  const imageSlide = data.urlPath
  return (
    <Container className='py-5 project'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h3>{data.name}</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <img src={data.logo} alt={data.name} className='my-3' />
        </Col>
      </Row>
      <Row>
        {imageSlide.map((e, i) => (
          <Col lg={4} md={4} sm={12} xs={12} key={i} className='py-2'>
            <YoutubeEmbed embedId={e.url} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default ProjectCard
