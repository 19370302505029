import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactForm from '../form/ContactForm'

const Contact = () => {
  return (
    <Container className='py-5 contact' id='contact'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1>Get in touch</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <ContactForm />
        </Col>
      </Row>
    </Container>
  )
}

export default Contact
