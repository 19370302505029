import React from 'react'
import './Home.css'
import { Col, Container, Row } from 'react-bootstrap'

const WhyHome = () => {
  return (
    <Container className='py-5 why-landing'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1>Why Colorbars?</h1>
        </Col>
      </Row>
      <Row className='pt-3'>
        <Col lg={6} md={6} sm={12} xs={12}>
          <p>
            With a team of experts, Colorbars is a boutique production house
            tailor-made around the required needs of our clients. Our limited
            client’s portfolio policy, provides our clients a focused and
            creative service.
          </p>
          <p>
            The size of our production house is important, it means fewer
            contact points between our clients and the creative staff, which
            means faster turnarounds and more targeted marketing.
          </p>
          <p>
            We are in a continuous development process that never ends, to excel
            our knowledge and services to successfully assist our clients.
            Professional functional and technical expertise coupled with
            extensive knowledge makes Colorbars the ideal choice to respond to
            the client.
          </p>
          <p>
            Whether the final project is a film, broadcast, PSA or even web
            report, our team can get the job done, and on time.
          </p>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <p>
            We handle your production needs from A to Z, from conceptualization
            to content and delivery with a clear vision to ensure your film
            success, and above all achieve objectives in mind.
          </p>
          <p>
            Colorbars offers its clients the best methods and tools in planning
            and implementing successful films. Our solutions will cut through
            the mumbo jumbo of the market, assist in your strategy and support
            your vision. Our films will grab the target market immediately and
            drive the right messages to the right audience.
          </p>
          <p>
            Our team has extensive experience in creating and delivering high
            quality and unique works. We believe that live communication is not
            only our essential part of our strategy but also a unique
            opportunity to talk or reward our clients face to face. We ensure
            both sides of the face to face are smiling.
          </p>
        </Col>
      </Row>
    </Container>
  )
}

export default WhyHome
